import React from "react"
import Layout from "../components/share/layout"
import Arteycultura from "../components/arteycultura"
import Footer from "../components/share/footer"
import { graphql, useStaticQuery } from "gatsby"

const Arteyculturademo = () => {
  const { allContentfulArteYCultura: { nodes: nodesBlog }, allContentfulGeneral: { nodes: nodesInfo } } = useStaticQuery(
    graphql`
      query {
        allContentfulArteYCultura(filter: {node_locale: {eq: "en-US"}}, sort: {fields: fechaDeOrden, order: DESC}) {
            nodes {
              id
              titulo
              tituloDeRespaldo
              descripcion
              fecha
              enExhibicion
              imagenDeLaEntrada {
                file {
                  fileName
                  url
                }
                description
                fluid {
                    ...GatsbyContentfulFluid
                }
              }
            }
          }
          allContentfulGeneral(filter: {node_locale: {eq: "en-US"}}) {
            nodes {
              blogSubtitulo
            }
          }
        }
    `)
  return (
    <Layout
      title="Arte y Cultura | UPC Cultural"
      metaTitle="Arte y Cultura | UPC Cultural"
      description="Visita y descubre experiencias, eventos y noticias de contenido artístico y cultural en el espacio de Arte y Cultura de UPC Cultural."
      subtitile="Visita y descubre experiencias, eventos y noticias de contenido artístico y cultural en el espacio de Arte y Cultura de UPC Cultural."
      metaDescripcin="Visita y descubre experiencias, eventos y noticias de contenido artístico y cultural en el espacio de Arte y Cultura de UPC Cultural."
      imgurl="//cultural.upc.edu.pe/static/UpcCultural_Share-a1f8e23f2b58627c09d19c7bfc611366.jpg"
      keywords="arte y cultura upc, arte y cultura upc cultural"
    >
      <Arteycultura
        nodesBlog={nodesBlog}
        nodesInfo={nodesInfo}
      />
      <Footer />
    </Layout>
  )
}

export default Arteyculturademo
